/* eslint-disable no-unused-expressions */
import React, { createContext, useState } from "react";
import { PropTypes } from "prop-types";
import { mediaQueries } from "../configs/_configSite";

export const ContextUI = createContext();

export const ProviderUI = ({ children }) => {
  let truthy = false;
  if (typeof window !== "undefined") {
    window.innerWidth > mediaQueries.md ? (truthy = false) : (truthy = true);
  }
  const [mobile, setMobile] = useState(truthy);
  const [appLoaded, setAppLoaded] = useState(false);

  return (
    <ContextUI.Provider
      value={{
        mobile,
        setMobile,
        appLoaded,
        setAppLoaded
      }}
    >
      {children}
    </ContextUI.Provider>
  );
};

ProviderUI.defaultProps = {
  children: <></>
};
ProviderUI.propTypes = {
  children: PropTypes.node
};
