// Colors
export const colors = {
  gold: "#D7B401",
  lightGreen: "#00B8A2",
  darkGray: "#414141",
  lightGray: "#747474",
  white: "#ffffff"
};

// Font Families
export const fonts = {
  primary: "BlackChancery",
  secondary: "Blinker",
  tertiary: "Source Serif Pro",
  quintary: "Poiret One",
  pentiary: "Montaga"
};

// Media Queries
export const mediaQueries = {
  xxs: 424.98,
  xs: 575.98,
  sm: 767.98,
  md: 991.98,
  lg: 1199.98,
  xl: 1439.98
};

// Main Navigation Items
export const mainNavigation = {
  home: "/",
  shop: "/shop",
  contact: "/contact",
  privacyPolicy: "/privacy-policy",
  termsOfService: "/terms-of-service"
};

// Contact Information
export const contact = {
  emailAddress: "Welcome@AnanaVestige.com"
};

// Social Links
export const socials = {
  instagram: "https://www.instagram.com/ananavestige",
  facebook: "https://www.facebook.com/ananavestige/"
};
